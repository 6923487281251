<template>
  <div class="header">
    <div class="pageName">
      <p>标题选中颜色</p>
      <el-color-picker v-model="data.active_color"></el-color-picker>
      <el-button type="primary" @click="resetData('active_color')">重置</el-button>
    </div>
    <div class="pageName">
      <p>价格颜色</p>
      <el-color-picker v-model="data.price_color"></el-color-picker>
      <el-button type="primary" @click="resetData('price_color')">重置</el-button>
    </div>
     <div class="pageName">
      <p>加购物车样式</p>
        <el-radio-group v-model="data.cart_type">
            <el-radio label="1">样式一</el-radio>
            <el-radio label="2">样式二</el-radio>
            <el-radio label="3">不显示</el-radio>
        </el-radio-group>
    </div>
     <div class="pageName">
      <p>加购颜色</p>
      <el-color-picker v-model="data.cart_color"></el-color-picker>
      <el-button type="primary" @click="resetData('cart_color')">重置</el-button>
    </div>

    <div v-for="(item,index) in data.goods_type" :key="index">
        <div class="pageName" >
            <p>商品来源</p>
            <el-radio-group v-model="item.type">
                <el-radio :label="t.val" v-for="t in goods_type" :key="t.val">{{t.label}}</el-radio>
            </el-radio-group>
        </div>
        <div class="pageName" >
            <p>标题{{index+1}}</p>
            <el-input placeholder="请输入内容" v-model="item.title"></el-input>
        </div>
        <div class="pageName" >
            <p>副标题{{index+1}}</p>
            <el-input placeholder="请输入内容" v-model="item.subtitle"></el-input>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props: ['data'],
    data() {
        return {
            goods_type:[{
                val:'explosive',
                label:'爆品'
            },{
                val:'group',
                label:'团购'
            },{
                val:'new',
                label:'新品'
            },{
                val:'popularity',
                label:'人气'
            }],
        }
    },
    created() {
    },
    methods: {
        resetData(attr){
            this.$emit('reset-data',{
                data:'goods',
                attr
            })
        },

    },
}
</script>

<style lang="less" scoped>
.header {
  padding: 30px;
  .pageName {
    display: flex;
    margin-bottom: 20px;

    p {
      width: 100px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .el-input {
      max-width: 300px;
    }
    .el-radio-group {
      height: 40px;
      display: flex;
      align-items: center;
    }
    .el-checkbox-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .el-checkbox {
        margin: 0 10px 10px 0;
      }
    }
    .el-color-picker {
      width: 200px;
      & /deep/ .el-color-picker__trigger {
        width: 100%;
      }
    }
  }
  .itemMid {
    position: relative;
    width: 160px;
    height: 80px;
    border: 1px dotted #333;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e1e1e1;
    .imgBox {
      width: 150px;
      height: 50px;
      object-fit: contain;
      margin-bottom: 23px;
    }
    .upload {
      width: 100%;
      line-height: 25px;
      position: absolute;
      bottom: 0;
      left: 0;
      color: #fff;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      text-align: center;
    }
  }
  .clearImg {
    color: #6d99ff;
    cursor: pointer;
    user-select: none;
    margin-left: 20px;
  }
  .tips {
      font-size: 14px;
      color: #9a9a9a;
      margin-left:100px;
      margin-bottom: 10px;
    }
}
</style>

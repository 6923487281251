<template>
  <div class="imgWindow" :style="{ background: imgWindow.bgColor }">
    <div :class="['layout','layout'+imgWindow.imgCount+imgWindow.layout]">
        <img :src="item.imgUrl" alt="" :class="['imgBox','index'+index]" v-for="(item,index) in imgWindow.list" :key="index"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgWindow: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
.layout{
    width: 100%;
    display: grid;
    gap: 9px;
    overflow: hidden;
}
.layout40{
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: repeat(2,80px);
    .index0{
        grid-column-end: span 2;
        grid-row-end: span 2;
    }
    .index1{
        grid-column-end: span 2;
    }
}
.layout41{
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: repeat(2,80px);
    .imgBox{
        grid-column-end: span 2;
    }
}
.layout42{
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: repeat(2,80px);
    .index0,.index2{
        grid-column-end: span 2;
    }
}
.layout43{
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: 90px;
}
.layout30{
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(2,80px);
    .index0{
        grid-row-end: span 2;
    }
}
.layout31{
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(2,80px);
    .index0{
        grid-column-end: span 2;
    }
}
.layout32{
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: 100px;
}
.layout33{
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: 130px;
}
.layout20{
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 80px;
}
.layout21{
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: 80px;
    .index0{
        grid-column-end: span 2;
    }
}
.layout22{
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 150px;
}
.layout23{
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 110px;
}
.imgBox{
    width: 100%;
    height: 100%;
    border-radius: 7px;
}
</style>

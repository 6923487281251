<template>
  <div class="bannerControl">
    <div class="bot">
      <p class="tips">*上传图片不超过1M，支持png、bmg、jpeg、jpg、gif</p>
      <div class="bgColor">
        <p>背景颜色</p>
        <el-color-picker v-model="controlDefault.bgColor"></el-color-picker>
        <el-button type="primary" @click="returnColor">重置</el-button>
      </div>
      <div class="numBox">
        <p>图片个数</p>
        <el-radio-group v-model="controlDefault.imgCount" @change="changeNum">
          <el-radio label="4">4个</el-radio>
          <el-radio label="3">3个</el-radio>
          <el-radio label="2">2个</el-radio>
        </el-radio-group>
      </div>
      <div class="numBox">
        <p>布局方式</p>
        <el-radio-group v-model="controlDefault.layout">
          <el-radio label="0">样式一</el-radio>
          <el-radio label="1">样式二</el-radio>
          <el-radio label="2">样式三</el-radio>
          <el-radio label="3">样式四</el-radio>
        </el-radio-group>
      </div>
      <div class="listItem">
        <vuedraggable
          v-model="controlDefault.list"
          v-bind="{
            animation: 200,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost',
            scroll: true
          }"
        >
          <transition-group>
            <div class="itemBox" v-for="(item, index) in controlDefault.list" :key="index">
              <div class="contantBox">
                <div class="left">
                  <div class="leftL">
                    <span @click="moveClick(0, index, item)" :class="index == 0 ? 'fcccc' : ''"
                      >&lt;</span
                    >
                    <span
                      @click="moveClick(1, index, item)"
                      :class="index == controlDefault.list.length - 1 ? 'fcccc' : ''"
                      >></span
                    >
                  </div>
                  <div class="leftR"><img :src="item.imgUrl" alt="" /></div>
                </div>
                <div class="right">
                  <div>
                    <div class="right-top">
                      <div class="inputBox">
                        <el-input
                          v-model="item.imgUrl"
                          disabled
                          placeholder="请选择图片"
                        ></el-input>
                        <div @click="getIndex(index)">
                          <el-button type="primary" @click="chooseImg(index)">选择</el-button>
                        </div>
                      </div>
                    </div>
                    <div class="right-top">
                      <div class="inputBox">
                        <el-input v-model="item.toPage" disabled placeholder="选择路径"></el-input>
                        <!-- <p>{{ item.toPage }}</p> -->
                        <el-button type="primary" @click="choosePage(index)">选择</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tips">
                {{
                  index == 0
                    ? imgSizeTips.firstTips
                    : index == 1
                    ? imgSizeTips.secondTips
                    : index == 2
                    ? imgSizeTips.thirdTips
                    : imgSizeTips.fourthTips
                }}
              </div>
            </div>
          </transition-group>
        </vuedraggable>
      </div>
    </div>
    <!-- 选择跳转页面弹框 -->
    <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
      <div>
        <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
      </div>
    </el-dialog>
    <!-- 选择图片弹框 -->
    <UploadSource
      v-if="chooseImgFlag"
      @changeStatus="changeStatus"
      :dialogVisible="chooseImgFlag"
      @getSource="getImgRes"
      type="image"
      :maxSize="1024 * 1024"
    ></UploadSource>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable'
import UploadSource from '@/components/uploadSource'
import ToPage from '@/views/addPages/toPage/index'
export default {
  props: {
    controlDefault: {
      type: Array,
      default: () => []
    }
  },
  components: {
    vuedraggable,
    UploadSource,
    ToPage
  },
  data() {
    return {
      index: -1,
      toPageFlag: false,
      chooseImgFlag: false,
      itemIndex: -1,
      img: 'orngfo',
      imgSizeTips: {
        firstTips: '建议该图片尺寸334px*398px',
        secondTips: '建议该图片尺寸334px*190px',
        thirdTips: '建议该图片尺寸158px*190px',
        fourthTips: '建议该图片尺寸158px*190px'
      }
    }
  },

  methods: {
    changeStatus(val) {
      this.chooseImgFlag = val
    },
    getIndex(index) {
      this.index = index
    },
    handleRemove(response) {
      if (response.code == 0) {
        this.controlDefault.list[this.index].imgURL = response.result.path
        this.controlDefault.list[this.index].status = '0'
        this.$emit('sonMethod', this.controlDefault)
      }
    },

    // 点击移动图片图位置
    moveClick(status, index, item) {
      if (status == 0) {
        // 向上移
        if (index == 0) {
          this.$message({
            message: '已经在最前面了~',
            type: 'error'
          })
          return
        }
        this.controlDefault.list.splice(index, 1)
        this.controlDefault.list.splice(index - 1, 0, item)
      } else {
        // 向下移
        if (index == this.controlDefault.list.length - 1) {
          this.$message({
            message: '已经在最后面了~',
            type: 'error'
          })
          return
        }
        this.controlDefault.list.splice(index + 2, 0, item)
        this.controlDefault.list.splice(index, 1)
      }
    },

    // 选择图片
    chooseImg(index) {
      this.index = index
      this.chooseImgFlag = !this.chooseImgFlag
    },
    // 选择跳转路径
    choosePage(index) {
      this.toPageFlag = true
      this.itemIndex = index
      this.$nextTick(() => {
        this.$refs.toPage.getPageArr()
      })
    },
    // 拿到跳转路径
    getPageRes(item) {
      this.controlDefault.list[this.itemIndex].toPage = item.wxroute
      this.toPageFlag = false
    },
    // 拿到图片
    getImgRes(imglist) {
      this.controlDefault.list[this.index].imgUrl = imglist[0].path
    },
    // 获取图片提示尺寸
    getTips() {
      let count = this.controlDefault.imgCount
      let layout = this.controlDefault.layout
      if (count === 2) {
        let str = ''
        if (layout == 0) {
          str = '建议该图片尺寸334px*190px'
          for (const key in this.imgSizeTips) {
            this.$set(this.imgSizeTips, key, str)
          }
        } else if (layout == 1) {
          this.$set(this.imgSizeTips, 'firstTips', '建议该图片尺寸446px*190px')
          this.$set(this.imgSizeTips, 'secondTips', '建议该图片尺寸222px*190px')
        } else if (layout == 2) {
          str = '建议该图片尺寸334px*334px'
          for (const key in this.imgSizeTips) {
            this.$set(this.imgSizeTips, key, str)
          }
        } else {
          str = '建议该图片尺寸334px*250px'
          for (const key in this.imgSizeTips) {
            this.$set(this.imgSizeTips, key, str)
          }
        }
      } else if (count === 3) {
        let str = ''
        if (layout == 0) {
          this.$set(this.imgSizeTips, 'firstTips', '建议该图片尺寸334px*398px')
          this.$set(this.imgSizeTips, 'secondTips', '建议该图片尺寸334px*190px')
          this.$set(this.imgSizeTips, 'thirdTips', '建议该图片尺寸334px*190px')
        } else if (layout == 1) {
          this.$set(this.imgSizeTips, 'firstTips', '建议该图片尺寸686px*190px')
          this.$set(this.imgSizeTips, 'secondTips', '建议该图片尺寸334px*190px')
          this.$set(this.imgSizeTips, 'thirdTips', '建议该图片尺寸334px*190px')
        } else if (layout == 2) {
          str = '建议该图片尺寸216px*216px'
          for (const key in this.imgSizeTips) {
            this.$set(this.imgSizeTips, key, str)
          }
        } else {
          str = '建议该图片尺寸216px*288px'
          for (const key in this.imgSizeTips) {
            this.$set(this.imgSizeTips, key, str)
          }
        }
      } else if (count === 4) {
        let str = ''
        if (layout == 0) {
          this.$set(this.imgSizeTips, 'firstTips', '建议该图片尺寸334px*398px')
          this.$set(this.imgSizeTips, 'secondTips', '建议该图片尺寸334px*190px')
          this.$set(this.imgSizeTips, 'thirdTips', '建议该图片尺寸158px*190px')
          this.$set(this.imgSizeTips, 'fourthTips', '建议该图片尺寸158px*190px')
        } else if (layout == 1) {
          str = '建议该图片尺寸167px*95px'
          for (const key in this.imgSizeTips) {
            this.$set(this.imgSizeTips, key, str)
          }
        } else if (layout == 2) {
          this.$set(this.imgSizeTips, 'firstTips', '建议该图片尺寸446px*190px')
          this.$set(this.imgSizeTips, 'secondTips', '建议该图片尺寸222px*190px')
          this.$set(this.imgSizeTips, 'thirdTips', '建议该图片尺寸222px*190px')
          this.$set(this.imgSizeTips, 'fourthTips', '建议该图片尺寸446px*190px')
        } else {
          str = '建议该图片尺寸158px*210px'
          for (const key in this.imgSizeTips) {
            this.$set(this.imgSizeTips, key, str)
          }
        }
      }
    },
    // 该变个数
    changeNum(val) {
      let lastNum = this.controlDefault.list.length
      if (val > lastNum) {
        let num = val - lastNum
        for (let i = 0; i < num; i++) {
          console.log('+')
          this.controlDefault.list.push({
            imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bigzheng.png',
            status: '1',
            toPage: '跳转页面'
          })
        }
      } else if (val < lastNum) {
        let num = lastNum - val
        for (let i = 0; i < num; i++) {
          this.controlDefault.list.splice(this.controlDefault.list.length - 1, 1)
        }
      }
      this.getTips()
    }
  },
  watch: {
    'controlDefault.layout'() {
      this.getTips()
    }
  }
}
</script>

<style lang="less" scoped>
.fcccc {
  color: #ccc;
  border: 1px solid #ccc !important;
  cursor: no-drop !important;
}
.bannerControl {
  width: 100%;
  .numBox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    p {
      margin-right: 20px;
    }
  }
  .bgColor {
    display: flex;
    align-items: center;
    margin: 20px 0;
    p {
      margin-right: 20px;
    }
    .el-color-picker/deep/.el-color-picker__trigger {
      width: 150px;
    }
  }
  .bot {
    padding: 30px;
    .tips {
      font-size: 14px;
      color: #9a9a9a;
    }
    .listItem {
      width: 100%;
      .itemBox {
        width: 100%;
        height: 105px;
        margin-top: 20px;
        .tips {
          margin-left: 50px;
          margin-top: 5px;
        }
        .contantBox {
          width: 100%;
          height: 85px;
          display: flex;
        }
        .left {
          display: flex;
          .leftL {
            font-family: SimSun;
            width: 30px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 20px;
            span {
              width: 16px;
              height: 16px;
              line-height: 16px;
              text-align: center;
              border-radius: 5px;
              display: block;
              border: 1px solid #333;
              font-weight: bold;
              cursor: pointer;
            }
            span:first-child {
              transform: rotate(90deg);
            }
            span:last-child {
              transform: rotate(90deg);
              margin-top: 10px;
            }
          }
          .leftR {
            height: 100%;
            width: 220px;
            overflow: hidden;
            // background-color: greenyellow;s
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .right {
          flex: 1;
          display: flex;
          justify-content: space-around;
          align-items: center;
          & > div:first-child {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .inputBox {
            width: 220px;
            height: 40px;
            border: 1px solid #ccc;
            border-radius: 3px;
            display: flex;
            align-items: center;
            position: relative;
            p {
              width: 150px;
              margin-left: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #9e9e9e;
            }
          }
        }
      }
    }
  }
}
</style>

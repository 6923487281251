<template>
   <el-main>
       <div class="box">
            <div style="height: 650px;position: relative;width: 350px;margin: 54px 0 0 15px;">
                <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/multiversion_phoneheader2.png" alt="" class="imagebg"
                :style="{'background':header.color}">
                <div class="logo" :style="{'background':header.color}">
                    <img :src="header.logo" alt="" class="image_logo">
                    <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/multiversion_minibutton.png" alt="" class="image_menu">
                </div>
                <div class="contain">
                    <!-- 头部 -->
                    <div :class="['header',chooseset=='header'?'component_checked':'component_canset']" @click="changeSet('header')"
                    :style="{'background':header.color}">
                        <div class="white"></div>

                        <!-- 定位 -->
                        <div class="location">
                            <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/common/location.png" class="image_location">
                            <span>门店名称</span>
                            <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/common/rightArrow_ffffff.png" class="image_rightArrow_ffffff">
                        </div>
                        <!-- 搜索 -->
                        <div class="search">
                            <div class="search_box">
                                <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/search_last.png" class="image_search">
                                <span>{{header.text}}</span>
                                <div class="search_btn" :style="{'background':header.color}">搜索</div>
                            </div>
                            <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/retail/card.png" class="image_card">
                        </div>
                        <!-- 标语 -->
                        <div class="slogan">
                            <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/retail/icon1.png" >
                            <span>便宜好货</span>
                            <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/retail/icon2.png">
                            <span>新鲜准时</span>
                            <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/retail/icon3.png">
                            <span>产地直达</span>
                            <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/retail/icon4.png">
                            <span>售后无忧</span>
                        </div>
                    </div>
                    <!-- 轮播图 -->
                    <div :class="['carousel',chooseset=='banner'?'component_checked':'component_canset']" @click="changeSet('banner')">
                        <el-carousel height="130px" class="carousel_box">
                            <el-carousel-item v-for="(item,index) in banner" :key="index">
                                <img :src="item.imgURL" alt="img_url">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <!-- 按钮组 -->
                    <div :class="['buttons',chooseset=='button'?'component_checked':'component_canset']" @click="changeSet('button')">
                        <div class="buttonbox" :style="{'background':buttons.bgColor}">
                            <div class="button">
                                <div class="button_item" v-for="(item,index) in buttons.list.slice(0,buttons.num*1>5?buttons.num/2:buttons.num)" :key="index">
                                    <img :src="item.img">
                                    <span>{{item.name}}</span>
                                </div>
                            </div>
                            <div class="button" v-if="buttons.num*1>5" style="margin-top:12px;">
                                <div class="button_item" v-for="(item,index) in buttons.list.slice(buttons.num/2,buttons.num)" :key="index">
                                    <img :src="item.img">
                                    <span>{{item.name}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 公告栏 -->
                    <div :class="['notice',chooseset=='notice'?'component_checked':'component_canset']" @click="changeSet('notice')">
                        <div class="noticebox" :style="{'--bgColor':notice.bgColor}">
                            <img :src="notice.icon" alt="icon" v-if="notice.icon" class="notice_icon">
                            <span :style="{'color':notice.color}">{{notice.text}}</span>
                        </div>
                    </div>
                    <!-- 图片橱窗 -->
                    <div :class="['images',chooseset=='images'?'component_checked':'component_canset']" @click="changeSet('images')">
                        <div class="imagesbox" :style="{'background':images.bgColor}">
                            <Images :imgWindow="images"></Images>
                        </div>
                    </div>
                    <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/retail/component.png" alt="" class="component">
                    <!-- 轮播图 -->
                    <div :class="['carousel carousel2',chooseset=='banner2'?'component_checked':'component_canset']" @click="changeSet('banner2')">
                        <el-carousel height="130px" class="carousel_box" :indicator-position="banner2.length>1?'':'none'">
                            <el-carousel-item v-for="(item,index) in banner2" :key="index">
                                <img :src="item.imgURL" alt="img_url">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <!-- 商品组tabs -->
                    <div :class="['tabs',chooseset=='goods'?'component_checked':'component_canset']"
                    @click="changeSet('goods')">
                        <div v-for="(item,index) in goods.goods_type" :key="index" class="tabs_item">
                            <div class="title">{{item.title}}</div>
                            <div class="subtitle"
                            :style="{'background':(index==0?goods.active_color:'transparent'),'color':(index==0?'#ffffff':'#999999')}">{{item.subtitle}}</div>
                        </div>
                    </div>
                    <!-- 商品 -->
                    <div class="goods">
                        <div class="goods_item" v-for="(item,index) in goods_list" :key="index">
                            <img :src="item.img" alt="">
                            <div class="goods_bottom">
                                <div class="goods_price" :style="{'color':goods.price_color}">
                                    ￥<span>{{item.price.split('.')[0]}}</span>.{{item.price.split('.')[1]}}
                                </div>
                                <i v-if="goods.cart_type*1<3" :class="[goods.cart_type=='1'?'el-icon-circle-plus':'iconfont icon-icon8']"
                                :style="{'color':goods.cart_color}"></i>
                            </div>
                        </div>
                    </div>
                    <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/retail/loading.png" alt="" style="width:100%;">
                </div>
                <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/retail/tabbar.png" alt="img" class="imagetabbar">
            </div>
        </div>
        <div class="flex1"></div>
        <!-- 设置 -->
        <div class="setting">
            <div class="setting_header">{{componentArr.find(item=>item.val==chooseset).label}}</div>
            <div class="setting_part">
                 <Header v-if="chooseset=='header'" :data="header" @reset-data="resetData"></Header>
                 <banner v-if="chooseset=='banner'" :data="banner"  @update-data="updateData($event,'banner')"></banner>
                 <Button v-if="chooseset=='button'" :buttons="buttons"></Button>
                 <notice v-if="chooseset=='notice'" :notice="notice" @reset-data="resetData"></notice>
                 <ImagesControl v-if="chooseset=='images'" :controlDefault="images"></ImagesControl>
                 <banner v-if="chooseset=='banner2'" :data="banner2" @update-data="updateData($event,'banner2')"></banner>
                 <Goods v-if="chooseset=='goods'" :data="goods" @reset-data="resetData"></Goods>
            </div>
        </div>
        <Preservation @preservation="submit"></Preservation>
    </el-main>
</template>

<script>
import { imgUrlHead } from '@/util/config.js'
import Preservation from '@/components/preservation'
import Header from '../components/header'
import banner from '../components/banner'
import Button from '../components/button'
import notice from '../components/notice'
import Images from '../components/images'
import ImagesControl from '../components/imagesControl'
import Goods from '../components/goods'
export default {
    components:{
        Preservation,
        Header,
        banner,
        Button,
        notice,
        Images,
        ImagesControl,
        Goods
    },
    data:function(){
        return {
            imgH: imgUrlHead,
            chooseset:'header',
            componentArr:[{
                label:'头部设置',
                val:'header'
            },{
                label:'轮播图',
                val:'banner'
            },{
                label:'按钮组',
                val:'button'
            },{
                label:'公告设置',
                val:'notice'
            },{
                label:'图片橱窗',
                val:'images'
            },{
                label:'轮播图',
                val:'banner2'
            },{
                label:'商品组',
                val:'goods'
            }],
            header:{
                color:'#008a3c',
                logo:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/changImg.png',
                text:'请输入搜索商品'
            },
            banner:[{
                imgURL:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/changImg.png',
                toPage:'跳转页面'
            }],
            buttons:{
                num:'10',
                bgColor:'#ffffff',
                list:[{
                    img:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                    name:'按钮文字',
                    page:'跳转页面'
                },{
                    img:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                    name:'按钮文字',
                    page:'跳转页面'
                },{
                    img:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                    name:'按钮文字',
                    page:'跳转页面'
                },{
                    img:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                    name:'按钮文字',
                    page:'跳转页面'
                },{
                    img:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                    name:'按钮文字',
                    page:'跳转页面'
                },{
                    img:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                    name:'按钮文字',
                    page:'跳转页面'
                },{
                    img:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                    name:'按钮文字',
                    page:'跳转页面'
                },{
                    img:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                    name:'按钮文字',
                    page:'跳转页面'
                },{
                    img:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                    name:'按钮文字',
                    page:'跳转页面'
                },{
                    img:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                    name:'按钮文字',
                    page:'跳转页面'
                }]
            },
            notice:{
                color:'#FD7C09',
                bgColor:'#FFF1D9',
                text:'公告：如您在购物的过程中有任何问题，请及时与我们联系，祝您购物愉快！',
                icon:''
            },
            images:{
                bgColor:'#ffffff',
                imgCount:'4',
                layout:'1',
                list:[{
                    imgUrl:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bigzheng.png',
                    toPage:''
                },{
                    imgUrl:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bigzheng.png',
                    toPage:''
                },{
                    imgUrl:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bigzheng.png',
                    toPage:''
                },{
                    imgUrl:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bigzheng.png',
                    toPage:''
                }]
            },
            banner2:[{
                imgURL:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/changImg.png',
                toPage:'跳转页面'
            }],
            goods:{
                active_color:'#008a3c',
                price_color:'#FF2626',
                cart_type:'1',
                cart_color:'#008a3c',
                goods_type:[{
                    type:'explosive',
                    title:'爆品推荐',
                    subtitle:'为你优选',
                },{
                    type:'group',
                    title:'超值拼团',
                    subtitle:'大家都在团',
                },{
                    type:'new',
                    title:'新品上线',
                    subtitle:'新品水果',
                },{
                    type:'popularity',
                    title:'人气专区',
                    subtitle:'发现精彩',
                }]
            },
            goods_list:[{
                img:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/retail/goods1.png',
                price:'11.00'
            },{
                img:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/retail/goods2.png',
                price:'28.99'
            },{
                img:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/retail/goods3.png',
                price:'17.90'
            },{
                img:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/retail/goods4.png',
                price:'12.00'
            }],
            hasdata:false
        }
    },
    created(){
        this.getInfo()
    },
    methods:{
        changeSet(str){
            this.chooseset = str
        },
        // 重置数据
        resetData(obj){
            this[obj.data][obj.attr] = null
        },
        updateData(e,str){
            this[str] = e
        },
        getInfo(){
            this.$axios.post(this.$api.retail.diypageInfo, {
                type:'2'
            })
            .then((res) => {
                if(res.code==0){
                     if(res.result.page_values){
                        let info = res.result.page_values
                        info.forEach(item=>{
                            if(item.data){
                                this[item.module_id] = item.data
                            }
                        })
                        this.hasdata = true
                    }
                }else {
                    this.$message.error(res.msg)
                }
            })
        },
        submit(){
            let info = [{
                module_id:'header',
                data:this.header
            },{
                module_id:'banner',
                data:this.banner
            },{
                module_id:'buttons',
                data:this.buttons
            },{
                module_id:'notice',
                data:this.notice
            },{
                module_id:'images',
                data:this.images
            },{
                module_id:'group',
                data:''
            },{
                module_id:'newcomer',
                data:''
            },{
                module_id:'banner2',
                data:this.banner2
            },{
                module_id:'goods',
                data:this.goods
            }]
            let data = {
                page_name:'零售多店首页',
                show_type:'2,3,4,5,6,7',
                page_value:info
            }
            if(this.hasdata){
                data.type = '2'
            }else{
                data.page_type = '2'
            }
            this.$axios.post(this.$api.retail.diypage, data)
            .then((res) => {
                if(res.code==0){
                    this.$message.success('保存成功')
                    this.getInfo()
                }else {
                    this.$message.error(res.msg)
                }
            })
        },

    }
}
</script>

<style lang="less" scoped>
.el-main{
    background-color: #fff;
    min-height: 100%;
    padding-top:0;
    padding-right:0;
    display: flex;
    align-items: flex-start;
    .box{
        width: 384px;
        height:770px;
        background-size: 100% 100%;
        margin-right:20px;
        margin-left: 20%;
        margin-top: 20px;
        background-image: url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/phonebg.png);
        position: relative;
        .imagebg{
            width: 100%;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 15;
        }
        .logo{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 50px;
            width: 100%;
            position: absolute;
            top: 20px;
            left: 0;
            z-index: 15;
            padding: 0 12px;
            .image_logo{
                height: 24px;
                width: auto;
            }
            .image_menu{
                height: 28px;
                width: auto;
            }
        }
        .contain{
            width: 350px;
            height: 100%;
            overflow: auto;
            position: relative;
            background-color: #F5F5F5;
            padding-bottom:53px;
            &::-webkit-scrollbar {
                width: 0px;
            }

            .header{
                background-color: #ffffff;
                padding: 70px 14px 74px;
                position: relative;
               .white{
                    content:'';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 46px;
                    background-color: #F5F5F5;
                    border-top-left-radius: 350px 46px;
                    border-top-right-radius: 350px 46px;
                }

                .location{
                    display: flex;
                    align-items: center;
                    font-size: 15px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #F5F2F2;
                    max-width: 100%;
                    .image_location{
                        width: 11px;
                        height: 14px;
                        margin-right: 5px;
                    }
                    .image_rightArrow_ffffff{
                        width: 13px;
                        height: 13px;
                    }
                }
                .search{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 7px;
                    .search_box{
                        width: 284px;
                        height: 28px;
                        background: #FFFFFF;
                        border-radius: 14px;
                        display: flex;
                        align-items: center;
                    }
                    .image_search{
                        width: 14px;
                        height: 14px;
                        margin-left: 11px;
                    }
                    span{
                        margin-left: 8px;
                        font-size: 13px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #999999;
                        flex: 1;
                    }
                    .search_btn{
                        width: 51px;
                        height: 26px;
                        border-radius: 13px;
                        text-align: center;
                        line-height: 26px;
                        font-size: 13px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #F5F2F2;
                        margin-right: 1px;
                    }
                    .image_card{
                        width: 27px;
                        height: 20px;
                    }
                }
                .slogan{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 14px;
                    img{
                        width: auto;
                        height: 15px;
                    }
                    span{
                        font-size: 12px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                    }
                    img+span{
                        margin-left: 4px;
                    }
                    span+img{
                        margin-left:11px;
                    }
                }
            }
            .carousel{
                margin-top: -66px;
                padding: 2px 12px;
                img{
                    width: 100%;
                    height: 100%;
                }
                .carousel_box{
                    border-radius: 7px;
                    position: relative;
                    z-index: 10;
                }
            }
            .buttons{
                margin-top: 9px;
                padding: 2px 12px;
                .buttonbox{
                    border-radius: 7px;
                    padding: 11px 9px;
                }
                .button{
                    display: flex;
                    justify-content: space-between;
                    .button_item{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        color: #333333;
                        font-size: 12px;
                        img{
                            width: 50px;
                            height: 50px;
                            margin-bottom: 6px;
                        }
                    }
                }
            }

            .notice{
                margin-top: 9px;
                padding: 2px 12px;
                .noticebox{
                    height: 34px;
                    border-radius: 17px;
                    display: flex;
                    align-items: center;
                    padding: 0 12px;
                    box-sizing: border-box;
                    position: relative;
                    &::after{
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background: var(--bgColor);
                        border-radius: 17px;
                    }
                }
                img{
                    width: 15px;
                    height: 15px;
                    margin-right: 9px;
                    position: relative;
                    z-index: 10;
                }
                span{
                    font-size: 13px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    position: relative;
                    z-index: 10;
                }
            }

            .images{
                margin-top: 9px;
                padding: 2px 12px;
                .imagesbox{
                    border-radius: 7px;
                    overflow: hidden;
                    padding: 9px;
                }
            }
            .component{
                width: 350px;
            }
            .carousel2{
                margin: 0;
            }
            .tabs{
                height: 72px;
                display: flex;
                align-items: center;
                justify-content: center;
                .tabs_item{
                    width: 88px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    &+.tabs_item:before{
                        content:'';
                        position: absolute;
                        width: 1px;
                        height: 30px;
                        background: #E6E6E6;
                        left: 0;
                        top: 50%;
                        margin-top: -15px;
                    }
                    .title{
                        font-size: 15px;
                        margin-bottom: 6px;
                    }
                    .subtitle{
                        padding: 4px 6px 3px;
                        border-radius: 10px;
                        font-size: 12px;
                    }
                }
            }
            .goods{
                padding: 0 12px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .goods_item{
                    background: #FFFFFF;
                    border-radius: 8px;
                    padding: 12px;
                    margin-bottom: 12px;
                    img{
                        width: 132px;
                    }
                    .goods_bottom{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .goods_price{
                            font-size: 13px;
                            span{
                                font-size: 15px;
                            }
                        }
                        i{
                            font-size: 22px;
                        }
                    }
                }
            }
        }
        .imagetabbar{
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 53px;
            z-index: 100;
        }
    }
    .setting{
        flex-shrink: 0;
        width: 596px;
        border: 1px solid #e6e6e6;
        box-sizing: border-box;
        padding-left: 20px;
        .setting_header{
            border-bottom: 1px solid #eee;
            height: 70px;
            display: flex;
            align-items: center;
            padding: 0 30px;
        }
        .setting_part{
            font-size: 14px;
        }
    }
}

    /deep/.el-carousel__indicators{
        .el-carousel__indicator--horizontal{
            padding: 8px 2px;
        }
        .el-carousel__button{
            width: 4px;
            height: 4px;
            border-radius: 2px;
        }
        .is-active button{
            width: 18px;
        }
    }
</style>

<template>
  <div class="header">
    <div class="pageName">
      <p>头部颜色</p>
      <el-color-picker v-model="data.color"></el-color-picker>
      <el-button type="primary" @click="resetData('color')">重置</el-button>
    </div>
    <div class="pageName">
        <p>店铺logo</p>
        <div class="itemMid">
            <img :src="data.logo" alt="icon" class="imgBox" v-if="data.logo"/>
            <div class="upload" @click="chooseImg">选择图片</div>
        </div>
       <div class="clearImg" @click="clearImg">清除图片</div>
    </div>
    <p class="tips">
        * 建议尺寸270px*52px，上传图片不超过1M，支持png、bmg、jpeg、jpg、gif
    </p>
    <div class="pageName">
        <p>搜索框文字</p>
        <el-input placeholder="请输入内容" v-model="data.text"></el-input>
    </div>
    <!-- 选图标 -->
    <UploadSource
      v-if="chooseImgFlag"
      @changeStatus="changeStatus"
      :dialogVisible="chooseImgFlag"
      @getSource="getImgRes"
    ></UploadSource>
  </div>
</template>

<script>
import UploadSource from '@/components/uploadSource'
export default {
    props: ['data'],
    components:{
        UploadSource
    },
    data() {
        return {
            index: -1,
            chooseImgFlag: false
        }
    },
    created() {
    },
    methods: {
        resetData(attr){
            this.$emit('reset-data',{
                data:'header',
                attr
            })
        },
        changeStatus(val) {
            this.chooseImgFlag = val
        },
        // 选择图片
        chooseImg() {
            this.chooseImgFlag = !this.chooseImgFlag
        },
        getImgRes(imglist) {
            this.data.logo = imglist[0].path
            if (imglist.length > 1) {
                this.$message({
                    message: '只能选取一张图，已自动截取第一张',
                    type: 'error'
                })
            }
            this.chooseImgFlag = false
        },
        // 清除图片
        clearImg() {
            this.data.logo = ''
        },
    },
}
</script>

<style lang="less" scoped>
.header {
  padding: 30px;
  .pageName {
    display: flex;
    margin-bottom: 20px;

    p {
      width: 100px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .el-input {
      max-width: 300px;
    }
    .el-radio-group {
      height: 40px;
      display: flex;
      align-items: center;
    }
    .el-checkbox-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .el-checkbox {
        margin: 0 10px 10px 0;
      }
    }
    .el-color-picker {
      width: 200px;
      & /deep/ .el-color-picker__trigger {
        width: 100%;
      }
    }
  }
  .itemMid {
    position: relative;
    width: 160px;
    height: 80px;
    border: 1px dotted #333;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e1e1e1;
    .imgBox {
      width: 150px;
      height: 50px;
      object-fit: contain;
      margin-bottom: 23px;
    }
    .upload {
      width: 100%;
      line-height: 25px;
      position: absolute;
      bottom: 0;
      left: 0;
      color: #fff;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      text-align: center;
    }
  }
  .clearImg {
    color: #6d99ff;
    cursor: pointer;
    user-select: none;
    margin-left: 20px;
  }
  .tips {
      font-size: 14px;
      color: #9a9a9a;
      margin-left:100px;
      margin-bottom: 10px;
    }
}
</style>
